import React, { useCallback } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Ant from "antd";
import { isNotEmpty, isEmail, isNumeric } from "../Domain/FormatValidator";
const queryString = require("query-string");
import { navigate } from "gatsby";
import { ErrorHint, TextInput } from "../Components/Order.Widget";
import AddressField from "../Components/AddressField";
import { Context } from "../AppContext";
import { ErrRegistor, errorHandler } from "../errors";

const UiState = {
  valid_email: 0,
  validating: 1,
  registering: 2,
  registered: 3,
};
const TYPE = {
  normal: "normal",
  enterprise: "enterprise",
};
const steps = ["驗證信箱", "點擊驗證信", "輸入密碼", "完成註冊"];

function RegisterPage(props) {
  const app = React.useContext(Context);
  const [uiState, setUiState] = React.useState(UiState.valid_email);
  const [values, setValues] = React.useState({
    username: "",
    email: "",
    password: "",
    password2: "",
  });
  const [entData, _setEntData] = React.useState({
    ent_name: "",
    contact_name: "",
    gui_number: "",
    ent_tel: "",
    tel_ext: "",
    ent_zip_code: "",
    ent_city: null,
    ent_district: null,
    ent_address: "",
  });
  const [accessToken, setAccessToken] = React.useState("");
  const [time, setTime] = React.useState(0);
  const timer = React.useRef();
  const [type, setType] = React.useState(TYPE.normal);
  const [validate, setValidate] = React.useState(false);
  const { profile } = app.state;

  React.useEffect(() => {
    if (profile) {
      navigate("/profile");
    }
  }, [profile]);

  React.useEffect(() => {
    (async () => {
      app.actions.setLoginModal(false);

      const queryParams = queryString.parse(location.search);
      let access_token = queryParams.access_token; //validation_token
      if (access_token) {
        setAccessToken(access_token);
        setUiState(UiState.registering);
      }

      let isEnt = await app.actions.isEnterpriseRegister();
      setType(isEnt ? TYPE.enterprise : TYPE.normal);
    })();
  }, [location.search]);

  const validEmail = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      if (!isNotEmpty(values.email)) {
        throw new ErrRegistor("信箱不可為空白");
      }

      if (!isEmail(values.email)) {
        throw new ErrRegistor("信箱格式錯誤");
      }

      await app.actions.validEmail({
        email: values.email,
      });
      setUiState(UiState.validating);
    } catch (ex) {
      setTime(0);
      if (ex && ex.error === "user_exist") {
        Ant.message.warning("此信箱已有存在帳號！");
      }
      if (ex && ex.error === "validation_error") {
        Ant.message.warning("超過兩分鐘才能再次驗證");
      } else {
        errorHandler(ex, "註冊失敗");
      }
    }
    app.actions.setLoading(false);
  }, [values]);

  const handleRegister = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      if (!isNotEmpty(values.password) || !isNotEmpty(values.password2)) {
        throw new ErrRegistor("密碼不可為空白");
      }

      if (values.password !== values.password2) {
        app.actions.setLoading(false);
        throw new ErrRegistor("密碼需要確認密碼一致");
      }

      if (type === TYPE.enterprise) {
        setValidate(true);
        if (
          !isNotEmpty(entData.ent_name) ||
          !isNotEmpty(entData.gui_number) ||
          !isNotEmpty(entData.ent_tel) ||
          !isNotEmpty(entData.ent_address) ||
          !isNotEmpty(entData.contact_name) ||
          !isNotEmpty(entData.ent_city) ||
          !isNotEmpty(entData.ent_district)
        ) {
          throw new ErrRegistor("欄位不能為空");
        } else if (
          !isNumeric(entData.gui_number) ||
          !isNumeric(entData.ent_tel)
        ) {
          throw new ErrRegistor("格式錯誤");
        }
      }

      if (type === TYPE.enterprise) {
        await app.actions.enterpriseRegister({
          access_token: accessToken,
          password: values.password,
          ...entData,
        });
      } else {
        await app.actions.register({
          access_token: accessToken,
          password: values.password,
        });
      }

      setUiState(UiState.registered);
    } catch (ex) {
      if (ex && ex.error == "invalid_token") {
        Ant.message.warning("此連結已過期，請重新寄送驗證信");
      } else if (ex && ex.error === "user_exist") {
        Ant.message.warning("此信箱已有存在帳號！");
      } else {
        errorHandler(ex, "註冊錯誤");
      }
    }
    app.actions.setLoading(false);
  }, [values, accessToken, type, entData]);

  const reset = React.useCallback(() => {
    setTime(120);
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(() => {
      setTime(prev => {
        if (prev <= 0) {
          clearInterval(timer.current);
          return 0;
        } else {
          return prev - 1;
        }
      });
    }, 1000);
  }, []);

  const setEntData = useCallback(obj => {
    _setEntData(prev => ({ ...prev, ...obj }));
  }, []);

  return (
    <Wrapper>
      <div className="center-content">
        <h1 style={{ textAlign: "center" }}>註冊</h1>
        <Ant.Steps current={uiState} style={{ marginBottom: 30 }}>
          {steps.map(step => (
            <Ant.Steps.Step key={step} title={step} />
          ))}
        </Ant.Steps>
        {(() => {
          switch (uiState) {
            case UiState.valid_email:
              return (
                <>
                  <h2>驗證信箱</h2>

                  <div>
                    <label htmlFor="login-username-input">電子信箱</label>
                    <Ant.Input
                      id="register-email-input"
                      value={values.email}
                      onChange={e => {
                        const value = e.target.value;
                        setValues(prev => ({ ...prev, email: value }));
                      }}
                    />
                  </div>

                  <BottomBar>
                    <Ant.Button
                      type="primary"
                      disabled={time > 0}
                      onClick={() => {
                        reset();
                        validEmail();
                      }}
                    >
                      寄送驗證信
                    </Ant.Button>
                  </BottomBar>
                </>
              );
            case UiState.validating:
              return (
                <>
                  <h2>已寄送驗證信</h2>
                  <div style={{ marginBottom: 10, color: "#999" }}>
                    請至信箱 {values.email}{" "}
                    查看是否收到驗證信，若沒有收到驗證信，可點選按鈕再次寄出。{" "}
                  </div>
                  <Ant.Alert
                    message="收到驗證信後，請點選「連結」跳回註冊頁，完成註冊程序"
                    type="info"
                    style={{ margin: "10px 0" }}
                  />
                  <BottomBar>
                    {time > 0 && (
                      <div style={{ color: "#999", padding: 15 }}>
                        {time} 秒後，可重寄驗證信
                      </div>
                    )}
                    <Ant.Button
                      type="primary"
                      disabled={time > 0}
                      onClick={() => {
                        reset();
                        validEmail();
                      }}
                    >
                      寄送驗證信
                    </Ant.Button>
                  </BottomBar>
                </>
              );
            case UiState.registering:
              return (
                <>
                  <h2>輸入密碼</h2>
                  <div>
                    <label htmlFor="register-password-input">密碼</label>
                    <Ant.Input
                      id="login-password-input"
                      type="password"
                      value={values.password}
                      onChange={e => {
                        const value = e.target.value;
                        setValues(prev => ({ ...prev, password: value }));
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="register-password-input">確認密碼</label>
                    <Ant.Input
                      id="login-password-input-confirm"
                      type="password"
                      value={values.password2}
                      onChange={e => {
                        const value = e.target.value;
                        setValues(prev => ({ ...prev, password2: value }));
                      }}
                    />
                  </div>

                  {type === TYPE.enterprise && (
                    <React.Fragment>
                      <div>
                        <label>公司名稱*</label>
                        <Ant.Input
                          value={entData.ent_name}
                          placeholder="公司名稱"
                          onChange={e => {
                            let value = e.target.value;
                            setEntData({ ent_name: value });
                          }}
                        />
                        {validate && !isNotEmpty(entData.ent_name) && (
                          <ErrorHint>公司名稱不能為空</ErrorHint>
                        )}
                      </div>

                      <div>
                        <label>統一編號*</label>
                        <Ant.Input
                          value={entData.gui_number || ""}
                          placeholder="統一編號"
                          onChange={e => {
                            const value = e.target.value;
                            setEntData({ gui_number: value });
                          }}
                        />
                        {validate && !isNotEmpty(entData.gui_number) && (
                          <ErrorHint>統一編號不能為空</ErrorHint>
                        )}
                      </div>

                      <div>
                        <label>聯絡人名稱*</label>
                        <Ant.Input
                          value={entData.contact_name}
                          placeholder="聯絡人名稱"
                          onChange={e => {
                            let value = e.target.value;
                            setEntData({ contact_name: value });
                          }}
                        />
                        {validate && !isNotEmpty(entData.contact_name) && (
                          <ErrorHint>聯絡人不能為空</ErrorHint>
                        )}
                      </div>

                      <div>
                        <label>公司電話*</label>
                        <div style={{ display: "flex" }}>
                          <Ant.Input
                            value={entData.ent_tel || ""}
                            placeholder="公司電話"
                            style={{ flex: 1 }}
                            onChange={e => {
                              const value = e.target.value;
                              setEntData({ ent_tel: value });
                            }}
                          />
                          <Ant.Input
                            placeholder="分機"
                            style={{ flexBasis: 140 }}
                            value={entData.tel_ext || ""}
                            onChange={e => {
                              const value = e.target.value;
                              setEntData({ tel_ext: value });
                            }}
                          />

                          {validate && !isNotEmpty(entData.ent_tel) ? (
                            <ErrorHint>公司電話不能為空</ErrorHint>
                          ) : validate && !isNumeric(entData.ent_tel) ? (
                            <ErrorHint>請確認公司電話格式</ErrorHint>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <label>公司地址*</label>
                        <AddressField
                          zip_code={entData.ent_zip_code}
                          city={entData.ent_city}
                          district={entData.ent_district}
                          address={entData.ent_address}
                          onChange={obj =>
                            setEntData({
                              ...(obj.zip_code !== undefined && {
                                ent_zip_code: obj.zip_code,
                              }),
                              ...(obj.city !== undefined && {
                                ent_city: obj.city,
                              }),
                              ...(obj.district !== undefined && {
                                ent_district: obj.district,
                              }),
                              ...(obj.address !== undefined && {
                                ent_address: obj.address,
                              }),
                            })
                          }
                        />

                        {validate && !isNotEmpty(entData.ent_address) && (
                          <ErrorHint>公司地址不能為空</ErrorHint>
                        )}
                        {validate &&
                          (!isNotEmpty(entData.ent_city) ||
                            !isNotEmpty(entData.ent_district)) && (
                            <ErrorHint>縣市鄉鎮不能為空</ErrorHint>
                          )}
                      </div>
                    </React.Fragment>
                  )}

                  <BottomBar>
                    <Ant.Button type="primary" onClick={handleRegister}>
                      註冊{" "}
                    </Ant.Button>
                  </BottomBar>

                  {type === TYPE.normal ? (
                    <div
                      style={{
                        display: "flex",
                        margin: "20px 0 ",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{}}>不是一般會員？</div>

                      <LinkButton
                        onClick={e => {
                          setType(TYPE.enterprise);
                          app.actions.setEnterpriseRegister(true);
                        }}
                      >
                        切換成企業會員註冊
                      </LinkButton>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        margin: "20px 0 ",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{}}>不是企業會員？</div>
                      <LinkButton
                        onClick={e => {
                          setType(TYPE.normal);
                          app.actions.setEnterpriseRegister(false);
                        }}
                      >
                        切換成一般會員註冊
                      </LinkButton>
                    </div>
                  )}
                </>
              );
            case UiState.registered:
              return (
                <Ant.Result
                  title="註冊成功!"
                  subTitle="請登入，並前往會員中心填寫會員基本資料"
                  status="success"
                  extra={[
                    <Ant.Button
                      type="primary"
                      onClick={() => {
                        app.actions.setLoginModal(true);
                      }}
                    >
                      登入
                    </Ant.Button>,
                  ]}
                />
              );
            default:
              return null;
          }
        })()}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;

  .center-content {
    max-width: 600px;
    margin: 20px auto;
  }
`;

const BottomBar = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LinkButton = styled.button`
  border: 0;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  color: #999;

  &:hover {
    opacity: 0.5;
  }
`;

export default connect((state, ownProps) => ({}), ActionCreator)(RegisterPage);
